import * as THREE from 'three';

const BRIGHT_YELLOW = 0xffd01a;
const CAMERA_FAR = 1000;
const CAMERA_FOV = 35;
const CAMERA_MAX_MOVEMENT = new THREE.Vector2(.15, .15);
const CAMERA_NEAR = 0.1;
const CAMERA_START_Z = 4;
const PARTICLE_COUNT = 150;
const NAV_DEPTH = .94;
const NAV_OFFSET = new THREE.Vector2(.32, .25);
const NAV_ROTATION = new THREE.Vector3(.08, 0, THREE.MathUtils.degToRad(-90));


export {
  BRIGHT_YELLOW,
  CAMERA_FAR,
  CAMERA_FOV,
  CAMERA_MAX_MOVEMENT,
  CAMERA_NEAR,
  CAMERA_START_Z,
  NAV_DEPTH,
  NAV_OFFSET,
  NAV_ROTATION,
  PARTICLE_COUNT,
}
