import {Back, Expo, gsap, Power0, Power1} from 'gsap';
import * as THREE from 'three';

import {ThreePlugin} from '../js/gsap/plugins/ThreePlugin';
import {AnimatableGroup} from './Animatable';

gsap.registerPlugin(ThreePlugin);

import {Logo} from './Logo';


export class HexNav extends AnimatableGroup {
  logo: Logo;
  private wrapper = new THREE.Group();

  constructor() {
    super();
    this.makeNav();
  }

  buildAnimation() {
    this.float();

    const timeline = gsap.timeline({
      onComplete: () => {
        this.spin();
      }
    });
    timeline
        .fromTo(
            this.logo, {
              three: {
                scale: 0,
                rotationY: -10,
                rotationZ: 20,
              },
            },
            {
              duration: .8,
              ease: Expo.easeInOut,
              three: {scale: 2.95, rotationY: 0, rotationZ: 0},
            })
        .to(this.logo, {
          duration: 1,
          ease: Back.easeInOut.config(3),
          three: {
            rotationY: 360,
          },
        },
            '>-0.3')
            .to(this.logo, {
              duration: .7,
              ease: Back.easeInOut.config(3),
              three: {
                scale: 2.5,
              },
            },
                '>-0.3');
  }

  float() {
    const duration = 2;
    const startPositionY = this.position.y;

    gsap.timeline()
        .to(this.wrapper, {
          three: {
            y: startPositionY + .02,
            rotationZ: 'random(0, 2)',
          },
          duration,
          ease: Power0.easeInOut,
        })
        .to(this.wrapper, {
          three: {
            y: startPositionY - .02,
            rotationZ: 'random(0, 2)',
          },
          duration,
          ease: Power0.easeInOut,
          yoyo: true,
          repeat: -1
        });
  }


  makeNav() {
    this.add(this.wrapper);
    this.logo = new Logo();
    this.logo.position.z = 3;
    this.wrapper.add(this.logo);
  }

  spin() {
    const duration = 6.5;
    gsap.timeline()
        .to(this.wrapper, {
          three: {
            rotationY: 12,
          },
          duration,
          ease: Power1.easeInOut,
        },
            '>-.2')
        .to(this.wrapper, {
          three: {
            rotationY: -12,
          },
          duration,
          ease: Power1.easeInOut,
          yoyo: true,
          repeat: -1
        });
  };
}
