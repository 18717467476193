import GUI from 'lil-gui';
import * as THREE from 'three';
import {Box3} from 'three';
import {CSG} from 'three-csgmesh/src/client/CSGMesh';
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry';
import {Font, FontLoader} from 'three/examples/jsm/loaders/FontLoader';
import * as constants from './constants';

const SCALE = {
  min: new THREE.Vector3(.14, .14, .4),
  max:new THREE.Vector3(.4, .4, .4),
}

const fontFile = './Roboto_Condensed_Bold.json';
const darkMaterial = new THREE.MeshPhysicalMaterial({
  color: 0x070707,
  roughness: .5,
  metalness: .25,
  clearcoat: 1,
  clearcoatRoughness: .3,
  reflectivity: 0,
});

const yellowMaterial = new THREE.MeshPhysicalMaterial({
  color: constants.BRIGHT_YELLOW,
  roughness: .15,
  metalness: 0,
  clearcoat: .35,
  clearcoatRoughness: .25,
  reflectivity: 0,
});

const whiteMaterial = new THREE.MeshPhysicalMaterial({
  color: 0xffffff,
  roughness: .15,
  metalness: 0,
  clearcoat: .35,
  clearcoatRoughness: .25,
  reflectivity: 0,
});

class Logo extends THREE.Group {
  private barSep: THREE.Mesh;
  private outerHex: THREE.Mesh;
  private fontLoader = new FontLoader();
  private font: Font;
  private innerHex: THREE.Mesh;
  private comingSoonInner: THREE.Group = new THREE.Group();
  private comingSoonOuter: THREE.Group = new THREE.Group();
  private giordanoInner: THREE.Group = new THREE.Group();
  private giordanoOuter: THREE.Group = new THREE.Group();
  private skyeInner: THREE.Group = new THREE.Group();
  private skyeOuter: THREE.Group = new THREE.Group();

  gui: GUI|null;


  constructor(gui?: GUI) {
    super();
    this.gui = gui ? gui : null;
    this.init();
  }

  async init() {
    this.makeHexes();
    this.makeBarSep();
    this.font = await this.loadFont();
    this.makeSkye();
    this.makeGiordano();
    this.makeComingSoon();
  }

  loadFont(): Promise<Font> {
    return new Promise((resolve) => {
      this.fontLoader.load(fontFile, (font) => {
        resolve(font);
      });
    });
  }

  makeHexes() {
    const hexGeometry = new THREE.CylinderBufferGeometry(1, 1, .08, 6);
    hexGeometry.rotateX(THREE.MathUtils.degToRad(90));

    this.innerHex = new THREE.Mesh(hexGeometry, yellowMaterial);
    this.outerHex = new THREE.Mesh(hexGeometry, darkMaterial);

    this.outerHex.castShadow = true;
    this.innerHex.castShadow = true;

    this.innerHex.receiveShadow = true;

    // Scale the part to cut out so it extends beyond the original it will cut
    // from.
    this.innerHex.scale.set(.85, .85, 1.5);
    this.innerHex.updateMatrix();

    const outerHexCsg = CSG.fromMesh(this.outerHex);
    const innerHexCsg = CSG.fromMesh(this.innerHex);

    this.outerHex = CSG.toMesh(
        outerHexCsg.subtract(innerHexCsg), new THREE.Matrix4(),
        (this.outerHex.material as THREE.MeshPhysicalMaterial));

    this.innerHex.scale.z = .15;
    this.innerHex.position.z = -.05;
    this.innerHex.updateMatrix();

    const outerHex2 = this.outerHex.clone();
    outerHex2.material = whiteMaterial;
    outerHex2.material.needsUpdate = true;
    outerHex2.scale.set(1.03, 1.03, .25);
    outerHex2.position.z = -.05;
    outerHex2.castShadow = true;
    outerHex2.receiveShadow = true;

    this.add(this.outerHex, this.innerHex, outerHex2);
  }

  makeBarSep() {
    this.barSep = new THREE.Mesh(
        new THREE.BoxBufferGeometry(1.3, .03, .03),
        darkMaterial,
    );
    this.barSep.position.z = .15;
    this.barSep.castShadow = true;
    this.add(this.barSep);
  }

  makeSkye() {
    const skye = 'SKYE';

    const textOptions = {
      font: this.font,
      size: .36,
      height: .02,
      curveSegments: 20,
      bevelEnabled: false,
    };

    let letterOffset = 0;
    const letterSpacing = .055;
    let i = 0;
    for (const letter of skye) {
      const geometry = new TextGeometry(letter, textOptions);
      const letterMeshInner = new THREE.Mesh(geometry, darkMaterial);
      const letterMeshOuter = new THREE.Mesh(geometry, whiteMaterial);
      const innerLetterSize = new THREE.Vector3();
      letterMeshInner.geometry.computeBoundingBox();
      letterMeshInner.geometry.boundingBox?.getSize(innerLetterSize);
      letterMeshInner.position.x = letterOffset + letterSpacing * i;
      letterMeshInner.position.y = .03;

      letterMeshInner.castShadow = true;
      letterMeshOuter.scale.set(1, 1, .6);
      const outerLetterBoundingBox =
          new Box3().setFromObject(letterMeshOuter);
      const outerLetterSize = new THREE.Vector3();
      outerLetterBoundingBox.getSize(outerLetterSize);
      const widthDelta: number = outerLetterSize.x - innerLetterSize.x;
      const heightDelta: number = outerLetterSize.y - innerLetterSize.y;
      letterMeshOuter.position.x =
          letterMeshInner.position.x - (widthDelta / 2);
      letterMeshOuter.position.y =
          letterMeshInner.position.y - (heightDelta / 2);

      letterOffset += innerLetterSize.x;
      i++;

      this.skyeInner.add(letterMeshInner);
      this.skyeOuter.add(letterMeshOuter);
    }

    const skyeInnerBoundingBox = new THREE.Box3().setFromObject(this.skyeInner);
    const center = new THREE.Vector3();
    skyeInnerBoundingBox.getCenter(center);
    this.skyeInner.translateX(-center.x);
    this.skyeOuter.translateX(-center.x);

    this.skyeInner.position.z = .1;
    this.skyeOuter.position.z = .07;
    this.skyeInner.position.y = .05;
    this.skyeOuter.position.y = .05;

    this.add(this.skyeInner, this.skyeOuter);
  }

  makeGiordano() {
    const giordano = 'GIORDANO';

    const textOptions = {
      font: this.font,
      size: .14,
      height: .03,
      curveSegments: 20,
      bevelEnabled: false,
    };

    let letterOffset = 0;
    const letterSpacing = .055;
    let i = 0;
    for (const letter of giordano) {
      const geometry = new TextGeometry(letter, textOptions);
      const letterMeshInner = new THREE.Mesh(geometry, darkMaterial);
      const letterMeshOuter = new THREE.Mesh(geometry, whiteMaterial);
      const innerLetterSize = new THREE.Vector3();
      letterMeshInner.geometry.computeBoundingBox();
      letterMeshInner.geometry.boundingBox?.getSize(innerLetterSize);
      letterMeshInner.position.x = letterOffset + letterSpacing * i;
      letterMeshOuter.castShadow = true;
      letterMeshInner.castShadow = true;
      letterMeshOuter.scale.set(1, 1, .6);
      const outerLetterBoundingBox =
          new Box3().setFromObject(letterMeshOuter);
      const outerLetterSize = new THREE.Vector3();
      outerLetterBoundingBox.getSize(outerLetterSize);
      const widthDelta: number = outerLetterSize.x - innerLetterSize.x;
      const heightDelta: number = outerLetterSize.y - innerLetterSize.y;
      letterMeshOuter.position.x =
          letterMeshInner.position.x - (widthDelta / 2);
      letterMeshOuter.position.y =
          letterMeshInner.position.y - (heightDelta / 2);

      letterOffset += innerLetterSize.x;
      i++;

      this.giordanoInner.add(letterMeshInner);
      this.giordanoOuter.add(letterMeshOuter);
    }

    const giordanoInnerBoundingBox =
        new THREE.Box3().setFromObject(this.giordanoInner);
    const center = new THREE.Vector3();
    giordanoInnerBoundingBox.getCenter(center);
    this.giordanoInner.translateX(-center.x);
    this.giordanoOuter.translateX(-center.x);

    this.giordanoInner.position.z = .1;
    this.giordanoOuter.position.z = .07;
    this.giordanoInner.position.y = -.2;
    this.giordanoOuter.position.y = -.2;


    this.add(this.giordanoInner, this.giordanoOuter);
  }

  makeComingSoon() {
    const comingSoon = 'COMING SOON';

    const textOptions = {
      font: this.font,
      size: .10,
      height: .03,
      curveSegments: 20,
      bevelEnabled: false,
    };

    let letterOffset = 0;
    const letterSpacing = .055;
    let i = 0;
    for (const letter of comingSoon) {
      const geometry = new TextGeometry(letter, textOptions);
      const letterMeshInner = new THREE.Mesh(geometry, darkMaterial);
      const letterMeshOuter = new THREE.Mesh(geometry, whiteMaterial);
      const innerLetterSize = new THREE.Vector3();
      letterMeshInner.geometry.computeBoundingBox();
      letterMeshInner.geometry.boundingBox?.getSize(innerLetterSize);
      letterMeshInner.position.x = letterOffset + letterSpacing * i;
      // letterMeshInner.castShadow = true;
      // letterMeshOuter.castShadow = true;

      letterMeshOuter.scale.set(1, 1, .6);
      const outerLetterBoundingBox =
          new Box3().setFromObject(letterMeshOuter);
      const outerLetterSize = new THREE.Vector3();
      outerLetterBoundingBox.getSize(outerLetterSize);
      const widthDelta: number = outerLetterSize.x - innerLetterSize.x;
      const heightDelta: number = outerLetterSize.y - innerLetterSize.y;
      letterMeshOuter.position.x =
          letterMeshInner.position.x - (widthDelta / 2);
      letterMeshOuter.position.y =
          letterMeshInner.position.y - (heightDelta / 2);

      letterOffset += innerLetterSize.x;
      i++;

      this.comingSoonInner.add(letterMeshInner);
      this.comingSoonOuter.add(letterMeshOuter);
    }

    const comingSoonInnerBoundingBox =
        new THREE.Box3().setFromObject(this.comingSoonInner);
    const center = new THREE.Vector3();
    comingSoonInnerBoundingBox.getCenter(center);
    this.comingSoonInner.translateX(-center.x);
    this.comingSoonOuter.translateX(-center.x);

    this.comingSoonInner.position.z = .1;
    this.comingSoonOuter.position.z = .07;

    this.comingSoonInner.position.y = -1.2;
    this.comingSoonOuter.position.y = -1.2;


    this.add(this.comingSoonInner, this.comingSoonOuter);
  }
}

export {
  Logo,
  SCALE
}
