import {Back, gsap, Power0, Power1, Power2} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {GUI} from 'lil-gui';
import {fromEvent} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';

import Stats from 'three/examples/jsm/libs/stats.module';
import dustImage from 'url:../assets/dust.png';
import backgroundImage from 'url:../assets/honeycomb_bg.jpg';
import backgroundImageNormal from 'url:../assets/honeycomb_bg_normal.jpg';
import backgroundImageNormalVert from 'url:../assets/honeycomb_bg_normal_vert.jpg';
import backgroundImageVert from 'url:../assets/honeycomb_bg_vert.jpg';

import {ThreePlugin} from '../js/gsap/plugins/ThreePlugin';
gsap.registerPlugin(ThreePlugin);

import {Animatable, AnimatableGroup, AnimatablePerspectiveCamera, AnimatablePoints} from './Animatable';
import * as constants from './constants';
import {HexNav} from './HexNav';
import {Utilities} from './Utilities';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, ThreePlugin);

export class Bzzz {
  private ambientLight: THREE.AmbientLight = new THREE.AmbientLight('white', 1);
  private animatables: Array<Animatable> = [];
  private background: THREE.Mesh;
  private backgroundGroup: AnimatableGroup = new AnimatableGroup();
  private camera: AnimatablePerspectiveCamera =
      new AnimatablePerspectiveCamera();
  private clock: THREE.Clock = new THREE.Clock();
  private directionalLight: THREE.DirectionalLight =
      new THREE.DirectionalLight('white', .75);
  private directionalLight2: THREE.DirectionalLight =
      new THREE.DirectionalLight('white', .75);
  private gui: GUI = null;
  private isDev = false;
  private hasNavBuildOccurred = false;
  private mousePosition = new THREE.Vector2();
  private nav: HexNav;
  private navBuildComplete = false;
  private navBg: THREE.Sprite;
  private particles: AnimatablePoints;
  private renderer: THREE.WebGLRenderer =
      new THREE.WebGLRenderer({antialias: true, alpha: true});
  // private orbitControls: OrbitControls =
  //     new OrbitControls(this.camera, this.renderer.domElement);
  private scene: THREE.Scene = new THREE.Scene();
  private scrollPosition: THREE.Vector2 = new THREE.Vector2(
      window.scrollX / window.innerWidth, window.scrollY / window.innerHeight);
  private viewportSizePixels: THREE.Vector2 =
      new THREE.Vector2(window.innerWidth, window.innerHeight);
  private viewportSize: THREE.Vector2;
  private stats: Stats = Stats();
  private static singleton: Bzzz = new Bzzz();

  static get App() {
    return this.singleton;
  }

  init() {
    this.configureLights();
    this.configureEventListeners();
    this.configureRenderer();
    this.configureCamera();
    this.makeNavBg();
    this.makeNav();
    this.configureLoadingManager();
    this.makeBackground();
    this.makeParticles();
    this.tick();
  }

  private captureMousePosition(event: MouseEvent|DeviceOrientationEvent) {
    // `clientX` and `clientY` are based on  the browser's upper-left 0,0 but
    // three.js uses the center of the canvas as 0,0. The following normalizes
    // the js reported clientX/Y positions to three.js coordinates between -1
    // and 1.
    if (event.type.indexOf('mouse') > -1) {
      this.mousePosition.x =
          ((event as MouseEvent).clientX / window.innerWidth) * 2 - 1;
      this.mousePosition.y =
          -((event as MouseEvent).clientY / window.innerHeight) * 2 + 1;
    } else {
      const rotationRange = 20;
      const betaMin = 0;
      const betaMax = 65;

      let gamma = THREE.MathUtils.clamp(
          (event as DeviceOrientationEvent).gamma, -rotationRange,
          rotationRange);
      gamma = THREE.MathUtils.mapLinear(
          gamma, -rotationRange, rotationRange, -1, 1);
      let beta = THREE.MathUtils.clamp(
          (event as DeviceOrientationEvent).beta, betaMin, betaMax);
      beta = THREE.MathUtils.mapLinear(beta, betaMin, betaMax, -1, 1);

      this.mousePosition.x = gamma;
      this.mousePosition.y = -beta;
    }
  }

  private configureCamera() {
    this.camera.fov = constants.CAMERA_FOV;
    this.camera.aspect =
        this.viewportSizePixels.width / this.viewportSizePixels.height;
    this.camera.near = constants.CAMERA_NEAR;
    this.camera.far = constants.CAMERA_FAR;
    this.camera.position.z = constants.CAMERA_START_Z;
    this.camera.lookAt(new THREE.Vector3(0, 0, 0));
    this.camera.updateProjectionMatrix();
    this.viewportSize = Utilities.getViewportSize(this.camera);
  }

  private configureEventListeners() {
    fromEvent(window, 'resize')
        .pipe(debounceTime(75))
        .subscribe(() => this.updateResizedWindow());

    fromEvent(window, 'scroll').pipe(debounceTime(10)).subscribe(() => {
      this.scrollPosition.x = window.scrollX / this.viewportSizePixels.width;
      this.scrollPosition.y = window.scrollY / this.viewportSizePixels.height;

      this.particles.position.y = this.scrollPosition.y;
    });

    fromEvent(window, 'mousemove')
        .subscribe((event: MouseEvent) => this.captureMousePosition(event));
    fromEvent(window, 'deviceorientation')
        .subscribe(
            (event: DeviceOrientationEvent) =>
                this.captureMousePosition(event));
  }

  private configureLights() {
    this.ambientLight.color = new THREE.Color(0xffffff);
    this.ambientLight.intensity = 1.2;

    this.directionalLight.color = new THREE.Color(0xffffff);
    this.directionalLight.intensity = .82;
    this.directionalLight.position.set(-1, .4, 10);
    this.directionalLight.castShadow = true;
    this.directionalLight.shadow.bias = -0.0001;
    this.directionalLight.shadow.mapSize = new THREE.Vector2(4096, 4096);

    this.directionalLight2.color = new THREE.Color(0xffffff);
    this.directionalLight2.intensity = .82;
    this.directionalLight2.position.set(1, -1.2, 10);
    // this.directionalLight2.castShadow = true;
    this.directionalLight2.shadow.bias = -0.0001;
    this.directionalLight2.shadow.mapSize = new THREE.Vector2(4096, 4096);

    this.scene.add(
        this.ambientLight, this.directionalLight, this.directionalLight2);
  }

  private configureLoadingManager() {
    THREE.DefaultLoadingManager.onLoad = async () => {
      if(this.hasNavBuildOccurred) return;

      gsap.timeline({
            delay: .45,
            onStart: () => {
              this.hasNavBuildOccurred = true;
            },
            onComplete: () => {
              this.navBuildComplete = true;
              this.scene.add(this.nav);
              this.nav.buildAnimation();
            }
          })
          .to(this.navBg, {
            three: {
              scaleY: this.viewportSize.height * .125,
            },
            duration: .4,
            ease: Power2.easeInOut
          });
    };


    THREE.DefaultLoadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
      const targetWidth = this.viewportSize.width;
      const percentageLoaded = itemsLoaded / itemsTotal;
      gsap.timeline().to(this.navBg, {
        three: {
          scaleX: targetWidth * percentageLoaded,
        },
        duration: .6,
        ease: Power2.easeInOut
      });
    };
  }

  private configureRenderer() {
    this.renderer.physicallyCorrectLights = true;
    this.renderer.shadowMap.enabled = true;
    // @ts-ignore
    this.renderer.shadowMap.bias = -0.0001;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.renderer.setSize(
        this.viewportSizePixels.width, this.viewportSizePixels.height);
    this.renderer.domElement.id = 'webgl-canvas';
    document.body.appendChild(this.renderer.domElement);
  }

  private async makeBackground() {
    const backgroundPlaneTextureLoader = new THREE.TextureLoader();

    let backgroundImageFile;
    let backgroundImageFileNormal;

    if (this.camera.aspect > 1.35) {
      backgroundImageFile = backgroundImage;
      backgroundImageFileNormal = backgroundImageNormal;

    } else {
      backgroundImageFile = backgroundImageVert;
      backgroundImageFileNormal = backgroundImageNormalVert;
    }

    let backgroundPlaneTexture: THREE.Texture =
        await backgroundPlaneTextureLoader.loadAsync(backgroundImageFile);
    let backgroundPlaneTextureNormal: THREE.Texture =
        await backgroundPlaneTextureLoader.loadAsync(backgroundImageFileNormal);

    const backgroundPlaneGeometry = new THREE.PlaneBufferGeometry(
        this.viewportSize.width, this.viewportSize.height, 20, 20);

    backgroundPlaneTexture =
        Utilities.cover(backgroundPlaneTexture, this.viewportSizePixels);

    const backgroundPlaneMaterial = new THREE.MeshPhongMaterial({
      map: backgroundPlaneTexture,
      normalMap: backgroundPlaneTextureNormal,
    })

    if (this.background) {
      this.scene.remove(this.background);
      this.background = null;
    }
    this.background =
        new THREE.Mesh(backgroundPlaneGeometry, backgroundPlaneMaterial);
    this.background.receiveShadow = true;

    const scaleFactor = Utilities.isMobile() ? 1.3 : 1.12;
    this.background.scale.set(scaleFactor, scaleFactor, scaleFactor);


    this.backgroundGroup.tick = (delta: number, elapsed: number) => {
      const parallax = this.mousePosition.clone();
      const parallaxMultiplier = 3;

      // `constants.CAMERA_MAX_MOVEMENT` x & y will be between -1 (full left)
      // and 1 (full right). The following two lines scale
      // `constants.CAMERA_MAX_MOVEMENT` x & .y within that range.
      parallax.x *= constants.CAMERA_MAX_MOVEMENT.x;
      parallax.y *= constants.CAMERA_MAX_MOVEMENT.y;

      const parallaxDeltaX =
          (parallax.x - this.backgroundGroup.position.x) * parallaxMultiplier;
      const parallaxDeltaY =
          (parallax.y - this.backgroundGroup.position.y) * parallaxMultiplier;

      this.backgroundGroup.position.x += parallaxDeltaX * delta;
      this.backgroundGroup.position.y += parallaxDeltaY * delta;
    };

    this.animatables.push(this.backgroundGroup);
    this.backgroundGroup.add(this.background);
    this.scene.add(this.backgroundGroup);
  }

  private makeNavBg() {
    this.navBg = new THREE.Sprite(new THREE.SpriteMaterial({color: 0x000000}));
    this.navBg.scale.x = .0;
    this.navBg.scale.y = .01;
    this.scene.add(this.navBg);
  }

  private makeNav() {
    this.nav = new HexNav();
    this.animatables.push(this.nav);
    this.scaleNav();

    this.nav.tick = (delta: number, elapsed: number) => {
      if (!this.navBuildComplete) return;
      const parallax = this.mousePosition.clone();
      const parallaxMultiplier = 3;

      // `constants.CAMERA_MAX_MOVEMENT` x & y will be between -1 (full
      // left) and 1 (full right). The following two lines scale
      // `constants.CAMERA_MAX_MOVEMENT` x & .y within that range.
      parallax.x *= constants.CAMERA_MAX_MOVEMENT.x;
      parallax.y *= constants.CAMERA_MAX_MOVEMENT.y;

      const parallaxDeltaX =
          (parallax.x - this.backgroundGroup.position.x) * parallaxMultiplier;
      const parallaxDeltaY =
          (parallax.y - this.backgroundGroup.position.y) * parallaxMultiplier;

      this.nav.position.x += parallaxDeltaX * delta;
      this.nav.position.y += parallaxDeltaY * delta;
    };
  }

  private makeParticles() {
    const points = [];
    for (let i = 0; i < constants.PARTICLE_COUNT; i++) {
      const posX = THREE.MathUtils.randFloatSpread(this.viewportSize.width);
      const posY = THREE.MathUtils.randFloat(
          -this.viewportSize.height / 2, this.viewportSize.height);
      const posZ = THREE.MathUtils.randFloat(0, this.camera.position.z - 1);
      const particle = new THREE.Vector3(posX, posY, posZ);
      points.push(particle);
    }

    const particlesGeometry = new THREE.BufferGeometry().setFromPoints(points);
    const particlesMaterial = new THREE.PointsMaterial({
      color: '#e3c000',
      map: new THREE.TextureLoader().load(dustImage),
      size: 0.05,
      transparent: true,
      depthWrite: false,
      opacity: THREE.MathUtils.randFloat(.1, .25),
      blending: THREE.AdditiveBlending
    });
    this.particles = new AnimatablePoints(particlesGeometry, particlesMaterial);
    this.particles.userData['directions'] =
        (new Array(constants.PARTICLE_COUNT) as Array<THREE.Vector2>);

    // Choose a random x and y direction for the particle to start traveling.
    for (let i = 0; i < this.particles.userData['directions'].length; i++) {
      const directionX = Math.round(Math.random()) ? 1 : -1;
      const directionY = Math.round(Math.random()) ? 1 : -1;

      this.particles.userData['directions'][i] =
          new THREE.Vector2(directionX, directionY);
    }

    this.particles.tick = () => {
      const positions: Array<number> =
          (this.particles.geometry.attributes.position.array as Array<number>);
      for (let i = 0; i < positions.length; i += 3) {
        const directionIndex = i / 3;
        const velocity = .00015;
        let directionVector: THREE.Vector2 =
            this.particles.userData['directions'][directionIndex];
        const velocityZScale = positions[i + 2] / 10000;
        positions[i] += (velocity + velocityZScale) * directionVector.x;
        positions[i + 1] += (velocity + velocityZScale) * directionVector.y;

        if (positions[i] < -.9 || positions[i] > .9) directionVector.x *= -1;
        if (positions[i + 1] < -.9 || positions[i + 1] > .9)
          directionVector.y *= -1;
      }

      this.particles.geometry.attributes.position.needsUpdate = true;
    };
    this.animatables.push(this.particles);
    this.backgroundGroup.add(this.particles);
  }

  private render() {
    this.renderer.render(this.scene, this.camera);
  }

  private scaleNav() {
    const viewportSizeFromLogoPov =
        Utilities.getViewportSize(this.camera, this.nav.logo.position.z);
    const navScale = viewportSizeFromLogoPov.width < .5 ?
        viewportSizeFromLogoPov.width * .5 :
        .2;

    this.nav.scale.set(navScale, navScale, navScale);
  }

  private scaleNavBg() {
    this.navBg.scale.set(
        this.viewportSize.width * 1.5, this.viewportSize.height * .125, 1);
  }

  private tick() {
    const delta = this.clock.getDelta();
    const elapsed = this.clock.getElapsedTime();
    for (const element of this.animatables) {
      element.tick(delta, elapsed);
    }

    this.render();
    this.stats.update();
    requestAnimationFrame(() => this.tick());
  }

  private updateResizedWindow() {
    this.viewportSizePixels.width = window.innerWidth;
    this.viewportSizePixels.height = window.innerHeight;
    this.viewportSize = Utilities.getViewportSize(this.camera);
    this.configureCamera();
    this.configureRenderer();
    this.makeBackground();
    this.scaleNav();
    this.scaleNavBg();
    this.render();
  }
}
