import * as THREE from 'three';
import {BufferGeometry, Material} from 'three';

import {IAnimatable} from './types/types';

class AnimatableGroup extends THREE.Group implements IAnimatable {
  constructor() {
    super();
  }
  tick(delta: number, elapsed: number) {};
}

class AnimatablePerspectiveCamera extends THREE.PerspectiveCamera implements
    IAnimatable {
  constructor(fov?: number, aspect?: number, near?: number, far?: number) {
    super(fov, aspect, near, far);
  }
  tick(delta: number, elapsed: number) {};
}

class AnimatablePoints extends THREE.Points implements IAnimatable {
  tick(delta: number, elapsed: number) {};
}

class AnimatableMesh<
    TMaterial extends Material | Material[] = Material | Material[],
    TGeometry extends BufferGeometry = BufferGeometry,
> extends THREE.Mesh implements IAnimatable {
  constructor(geometry?: TGeometry, material?: TMaterial) {
    super(geometry, material);
  }
  tick(delta: number, elapsed: number) {};
}

type Animatable =
    AnimatableGroup|AnimatableMesh|AnimatablePerspectiveCamera|AnimatablePoints;

export {
  Animatable,
  AnimatableGroup,
  AnimatableMesh,
  AnimatablePerspectiveCamera,
  AnimatablePoints,
}
